import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from "react";
import { css } from "@emotion/core";
import { useTheme } from "emotion-theming";
import styled from "@emotion/styled";
import addToMailchimp from "gatsby-plugin-mailchimp";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";
import { grid } from "../../../../styles/grid";
import {
  colors,
  hexRgba,
  mediaquery,
  rem,
  scaleSpacing,
  zIndexes,
} from "../../../../styles/theme";
import Link from "../../Atoms/Link";
import Button from "../../Atoms/Button";
import Svg from "../../Atoms/Svg";
import {
  Noto,
  NotoBody,
  OswaldH1,
  OswaldH2,
  OswaldH3,
  OswaldH4,
  OswaldH5,
} from "../../Atoms/Typography";
import { UIContext } from "../../../contexts/ui";

const flexStyle = css`
  display: flex;
  padding: 0;
  height: 100%;
  flex-direction: column;
  justify-content: center;

  ${mediaquery.sm(css`
    padding: ${scaleSpacing(16)} calc(var(--col));
    flex-direction: row;
    justify-content: space-between;
  `)};
`;

const gridStyle = css`
  ${grid};
  padding-top: ${scaleSpacing(26)};
  padding-bottom: ${scaleSpacing(22)};

  ${mediaquery.sm(css`
    padding-top: ${scaleSpacing(34)};
    padding-bottom: ${scaleSpacing(34)};
  `)};
`;

const Container = styled.section`
  background: ${({ bgColor }) => bgColor};
  position: relative;
  overflow: hidden;
  ${({ inPopin }) =>
    inPopin &&
    css`
      overflow: unset;
    `};
  ${({ inPopin }) => (inPopin ? flexStyle : gridStyle)};

  ${({ inPopin }) =>
    inPopin &&
    css`
      ${mediaquery.md(css`
        padding: 0;
      `)};
    `};

  ${({ mini }) =>
    mini &&
    css`
      display: flex;
      flex-direction: column;
      padding: ${scaleSpacing(10)} calc(var(--external-gutter));
      ${mediaquery.md(css`
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: ${scaleSpacing(14)} ${scaleSpacing(14)};
        z-index: ${zIndexes.top};
      `)};
    `};
`;

const BackgroundLogo = styled.svg`
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: auto;
  right: -100%;

  ${mediaquery.sm(css`
    height: 120%;
    width: 100%;
    bottom: 0;
    right: -5%;
  `)};

  ${({ inPopin }) =>
    inPopin &&
    css`
      height: calc(100% + 132px);
      top: -64px;
      right: -250%;

      ${mediaquery.xs(css`
        height: calc(100% + 132px);
        width: auto;
        right: -100%;
        top: -64px;
      `)};
      ${mediaquery.sm(css`
        height: calc(100% + 132px);
        width: auto;
        right: -50%;
        top: -64px;
      `)};

      ${mediaquery.md(css`
        top: -64px;
        height: calc(120% + 132px);
        width: calc(var(--col) * 18 + var(--gutter-size));
        left: 0;
      `)};
    `}
`;

const FirstPanel = styled.div`
  grid-column: span 4;

  *:not(:last-child) {
    margin-bottom: ${scaleSpacing(4)};
  }

  ${mediaquery.sm(css`
    grid-column: span 10;
  `)}
  ${({ inPopin }) => css`
    ${mediaquery.md(css`
      grid-column: ${inPopin ? "none" : "5 / span 6"};
      ${inPopin &&
      css`
        width: calc(var(--col) * 5 - var(--gutter-size));
      `};
    `)};
  `};

  ${({ mini }) =>
    mini &&
    css`
      flex-direction: column;
      margin-top: 0;
      flex: 100% 0 1;
      ${mediaquery.md(css`
        flex: 50% 0 1;
      `)};
    `};
`;

const Title = styled.h2`
  ${OswaldH2};
  color: ${colors.white};
  margin-top: ${scaleSpacing(8)};
  margin-right: ${scaleSpacing(10)};

  ${mediaquery.sm(css`
    ${OswaldH1};
    margin-top: 0;
    margin-right: 0;
  `)}

  ${({ inPopin }) =>
    inPopin &&
    css`
      ${OswaldH4};
      ${mediaquery.sm(css`
        ${OswaldH3};
      `)}
    `};
  ${({ mini }) =>
    mini &&
    css`
      ${OswaldH5}
      letter-spacing: 0.002em;
      margin-top: 0;
      margin-bottom: ${scaleSpacing(2)};
    `};
`;

const SecondPanel = styled.div`
  grid-column: span 4;
  margin-top: ${scaleSpacing(3)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${mediaquery.sm(css`
    margin-top: 0;
    grid-column: span 14;
  `)}
  ${({ inPopin }) => css`
    ${mediaquery.md(css`
      grid-column: ${inPopin ? "none" : "14 / span 6"};
      ${inPopin &&
      css`
        width: calc(var(--col) * 6);
      `};
    `)};
  `};

  ${({ mini }) =>
    mini &&
    css`
      flex-direction: column;
      margin-top: 0;
      flex: 100% 0 0;
      ${mediaquery.md(css`
        flex: 30% 0 1;
        justify-content: flex-start;
        align-items: flex-start;
      `)};
    `};
`;

const Subtitle = styled.p`
  ${NotoBody};
  color: ${colors.white};
  margin-right: ${scaleSpacing(10)};

  ${mediaquery.sm(css`
    margin-right: 0;
  `)}

  ${({ inPopin }) =>
    inPopin &&
    css`
      ${NotoBody};
    `};
`;

const StyledLink = styled(Button)`
  color: ${colors.white};
  padding: 0;
`;

const LegalsMention = styled.div`
  ${Noto};
  font-size: ${rem(11)};
  line-height: ${rem(14)};

  letter-spacing: 0.2px;
  margin-top: ${scaleSpacing(4)};
  align-self: flex-end;
  color: ${colors.white};
  a {
    display: inline;
    color: ${colors.white};
    font-size: ${rem(11)};
    line-height: ${rem(14)};
  }
`;

const Form = styled.form`
  width: 100%;
  margin: 0 auto;
  margin-top: ${scaleSpacing(6)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${mediaquery.sm(css`
    max-width: none;
    margin-top: 0;
  `)};

  ${({ mini }) =>
    mini &&
    css`
      flex-direction: column;
      ${mediaquery.sm(css`
        margin-top: ${scaleSpacing(6)};
      `)};
      ${mediaquery.md(css`
        flex-direction: row;
        flex: 30% 0 1;
        align-items: flex-start;
      `)};
    `};
`;

const Input = styled.input`
  width: 100%;
  height: ${scaleSpacing(14)};
  padding: 0 ${scaleSpacing(6)};
  border-radius: ${scaleSpacing(7)};
  border: none;
  outline: none;
  ${NotoBody};
  color: ${colors.white};
  background: ${({ bgColor }) => bgColor};
  border: 1px solid ${hexRgba(colors.white, 0.4)};

  &::placeholder {
    color: ${colors.white};
  }

  &:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px ${({ bgColor }) => bgColor && hexRgba(bgColor, 0.73)}
      inset !important;
    -webkit-text-fill-color: ${colors.white} !important;
  }
`;

const InputWithIcon = styled.div`
  position: relative;
  width: 100%;

  ${mediaquery.sm(css`
    input {
      padding-right: ${scaleSpacing(12)};
    }
  `)};
`;

const IconContainer = styled.div`
  position: absolute;
  width: ${rem(24)};
  height: ${rem(24)};
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  right: ${scaleSpacing(6)};
  transform: translateY(-50%);
`;

const MessageContainer = styled(animated.div)`
  position: absolute;
  width: 100%;
  background: ${colors.white};
  padding: ${scaleSpacing(4)} ${scaleSpacing(6)};
  border-radius: ${rem(5)};
  top: calc(100% + 11px);
  box-shadow: 0px 1px 10px 0px ${hexRgba(colors.black, 0.3)};
  display: flex;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    width: ${scaleSpacing(2)};
    height: ${scaleSpacing(2)};
    border-radius: ${rem(2)};
    background: ${colors.white};
    transform: translate3d(-50%, 0, 0) rotate(45deg);
    top: -${rem(3)};
    left: 50%;
  }
`;

const StatusContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${scaleSpacing(8)};
  height: ${scaleSpacing(10)};
  background: ${colors.lightgrey};
  border-radius: ${scaleSpacing(4)};
`;

const Message = styled.div`
  ${Noto};
  font-size: ${rem(10)};
  line-height: ${rem(18)};

  color: ${colors.darkgrey1};
  margin-left: ${scaleSpacing(2)};

  a {
    color: ${colors.black};
    font: inherit;
  }
`;

const Newsletter = ({
  emojis,
  title,
  subtitle,
  legalsMention,
  legalsMentionUrl,
  inPopin,
  mini,
  ctaLabel,
  enableManageLink,
}) => {
  const theme = useTheme();
  const inputRef = useRef(null);
  const [text, setText] = useState("");
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [success, setSuccess] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  let timeout;
  const { t } = useTranslation();
  const { openModal } = useContext(UIContext);

  const transition = useTransition(showMessage, {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 0 },
  });

  const handleSubmit = async e => {
    inputRef.current.blur();
    setErrorMessage(undefined);
    e.preventDefault();

    const response = await addToMailchimp(inputRef.current.value);
    setSuccess(response.result === "success");

    if (response.result === "success") {
      setSuccess(true);
      setText("");
    } else {
      setSuccess(false);
      setErrorMessage(response);
      setShowMessage(true);
    }
    inputRef.current.value === "";
    timeout = setTimeout(() => {
      setShowMessage(false);
      setText("");
    }, 5000);
  };

  useEffect(() => {
    success &&
      openModal({
        modalId: "newsletterSuccess",
      });
  }, [openModal, success]);

  const handleFocus = () => {
    if (errorMessage) {
      clearTimeout(timeout);
      setShowMessage(false);
    }
  };

  const handleManageNewsletters = useCallback(() => {
    openModal({
      modalId: "newsletter",
      modalParams: { template: "allNewsletterModal" },
    });
  }, [openModal]);

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [timeout]);

  return (
    <Container inPopin={inPopin} bgColor={theme?.primary} mini={mini}>
      {!mini && (
        <BackgroundLogo
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 1266 504"
          inPopin={inPopin}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M888.676 -262C819.234 -202.006 768.894 -118.496 757.93 -27.9528C736.483 90.1052 790.134 191.958 875.988 250.987L260.734 635.503H0L126.126 -262H888.676ZM1266.36 635.503H617.253L1170.99 283.122C1205.34 261.262 1237.47 236.643 1266.36 209.542V635.503Z"
            fill="white"
            fillOpacity="0.1"
          />
        </BackgroundLogo>
      )}
      <FirstPanel inPopin={inPopin} mini={mini}>
        {emojis && <p>{emojis}</p>}
        {title && (
          <Title mini={mini} inPopin={inPopin}>
            {title}
          </Title>
        )}
        {subtitle && <Subtitle inPopin={inPopin}>{subtitle}</Subtitle>}

        {ctaLabel && enableManageLink && (
          <StyledLink
            buttonTheme={"tertiaryDark"}
            onClick={handleManageNewsletters}
          >
            {ctaLabel}
          </StyledLink>
        )}
      </FirstPanel>
      <SecondPanel mini={mini} inPopin={inPopin}>
        <Form onSubmit={handleSubmit} mini={mini}>
          <div css={{ position: "relative", width: "100%" }}>
            <InputWithIcon
              css={
                mini &&
                css`
                  ${mediaquery.md(css`
                    width: auto;
                    margin-right: ${scaleSpacing(2)};
                  `)};
                `
              }
            >
              <Input
                onFocus={handleFocus}
                ref={inputRef}
                placeholder={t("common_newsletter_email_placeholder")}
                value={text}
                onChange={e => setText(e.target.value)}
                type="email"
                name="EMAIL"
                required
                bgColor={theme?.primary}
                mini={mini}
              />
              <IconContainer>
                <Svg
                  name="arrowlink"
                  size={10}
                  height={14}
                  fill={colors.white}
                />
              </IconContainer>
            </InputWithIcon>
            {transition(
              (style, item) =>
                item && (
                  <MessageContainer style={style}>
                    <StatusContainer>
                      {success ? (
                        <Svg
                          name="tick"
                          size={15}
                          height={15}
                          fill={colors.cidarkgrey2nema}
                        />
                      ) : (
                        <Svg
                          name="warning"
                          size={4}
                          height={15}
                          fill={colors.cinema}
                        />
                      )}
                    </StatusContainer>
                    <Message
                      dangerouslySetInnerHTML={{ __html: errorMessage.msg }}
                    />
                  </MessageContainer>
                )
            )}
          </div>
          <Input
            css={css`
              margin-top: ${scaleSpacing(4)};
              ${NotoBody};
              color: ${colors.black};
              background: ${colors.white};

              ${mini &&
              css`
                ${mediaquery.md(css`
                  margin-top: 0;
                  width: ${scaleSpacing(39)};
                `)};
              `};
            `}
            type="submit"
            value="Valider"
            name="subscribe"
            id="mc-embedded-subscribe"
            className="button"
            bgColor={theme?.primary}
          />
        </Form>
        {legalsMention && (
          <LegalsMention>
            {legalsMention}{" "}
            <Link link={{ url: legalsMentionUrl }}>
              {t("common_newsletter_legalsmentionlinklabel")}
            </Link>
          </LegalsMention>
        )}
      </SecondPanel>
    </Container>
  );
};

Newsletter.propTypes = {
  theme: PropTypes.string.isRequired,
  emojis: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  legalsMention: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  legalsMentionUrl: PropTypes.string,
  ctaLabel: PropTypes.string,
  inPopin: PropTypes.bool.isRequired,
  mini: PropTypes.bool,
  enableManageLink: PropTypes.bool,
};

Newsletter.defaultProps = {
  theme: "institut",
  inPopin: false,
  mini: false,
};

export default React.memo(Newsletter);
